import { memo, useMemo } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { getCurrentEnv } from '@/utilities/helpers/get-current-env.helpers'
import {
  AcfImageObject,
  AcfLinkItemProps,
  WordpressPostFeaturedImage,
} from '@typings'

import styles from './ra-image.module.scss'

import { getImageSizes } from '@utilities/helpers/get-image-sizes.helpers'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'
import { RaRichText } from '../index.core'

interface RaImageProps {
  image: AcfImageObject | WordpressPostFeaturedImage
  preload?: boolean
  width?: number
  height?: number
  tornEdge?: boolean
  tornEdgeColor?: string
  elevation?: 'none' | 'light' | 'heavy'
  rotate?: 'left' | 'right'
  polaroid?: boolean
  polaroidContent?: React.ReactNode
  caption_title?: string
  caption_subtitle?: string
  maxWidth?: boolean
  cover?: boolean
  link?: AcfLinkItemProps
}

export const RaImage = memo(
  ({
    image,
    preload = false,
    width,
    height,
    tornEdge,
    tornEdgeColor = 'var(--color-white)',
    elevation = 'none',
    rotate,
    polaroid,
    polaroidContent,
    maxWidth = false,
    cover,
    link,
  }: RaImageProps) => {
    const sizes = getImageSizes(image?.sizes)
    const isErlebe = getCurrentEnv() === 'ERLEBE'

    const srcSet = useMemo(() => {
      if (!sizes.length) return null

      return sizes
        .map(s => {
          return `${s.src} ${s.width}w`
        })
        .join(',')
    }, [])

    if (!image) return null

    const imageClasses = useMemo(() => {
      return clsx(
        styles['ra-image'],
        cover && styles['ra-image--cover'],
        !isErlebe && rotate && styles[`ra-image--rotate-${rotate}`],
        elevation && styles[`ra-image--elevation-${elevation}`],
        maxWidth && styles[`ra-image--max-width`]
      )
    }, [elevation, cover, rotate, maxWidth, isErlebe])

    if (polaroid && !width) {
      width = 373
      height = 280
    }

    const ImageElement = useMemo(() => {
      return (
        <img
          alt={image.alt}
          width={width || image.width}
          height={height || image.height}
          loading={preload ? 'eager' : 'lazy'}
          srcSet={srcSet}
          className={imageClasses}
          src={image.url}
        />
      )
    }, [imageClasses, srcSet])

    if (tornEdge) {
      return (
        <div
          className={styles['torn-edge']}
          style={
            {
              '--torn-edge-color': tornEdgeColor,
            } as React.CSSProperties
          }>
          {ImageElement}
        </div>
      )
    }

    if (polaroid) {
      return (
        <div
          className={clsx(
            styles['ra-image--polaroid'],
            polaroidContent && styles['ra-image--polaroid-content']
          )}>
          {ImageElement}
          {polaroidContent}
        </div>
      )
    }

    if (link?.url) {
      return (
        <Link
          href={stripFrontendUrl(link.url)}
          title={link.title}
          target={link.target}>
          {ImageElement}
        </Link>
      )
    }

    return ImageElement
  }
)

RaImage.displayName = 'RaImage'
