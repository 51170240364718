import {
  RaButton,
  RaHeading,
  RaImage,
  RaPaper,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'
import { stripWpUrl } from '@utilities/helpers/strip-wp-url.helpers'

import styles from './ra-card-region.module.scss'

export const RaCardRegion = ({ card, isEven, index }) => {
  const url = stripWpUrl(card.link.url)
  const pathOnly = stripFrontendUrl(url)

  const link = {
    ...card.link,
    url: pathOnly,
  }

  return (
    <RaPaper
      tornEdge
      padding={6}
      paddingMobile={4}>
      <div className={styles['card']}>
        <RaImage
          image={card.image}
          elevation="light"
          rotate={isEven ? 'left' : 'right'}
          width={456}
          height={342}
        />

        <div className={styles['card__content']}>
          <div className={styles['card__header']}>
            <div className={styles['card__index']}>
              <RaTypography
                weight="semibold"
                size="xl"
                component="span"
                color="primary">
                {index + 1}
              </RaTypography>
            </div>
            <RaTypography
              component="span"
              weight="bold"
              size="s">
              {card.label}
            </RaTypography>
          </div>

          <div className={styles['card__title']}>
            <RaHeading
              component="h2"
              noPadding>
              {card.title}
            </RaHeading>
          </div>

          <div className={styles['card__details']}>
            <RaRichText content={card.content} />
          </div>
          <div className={styles['card__btn']}>
            <RaButton link={link} />
          </div>
        </div>
      </div>
    </RaPaper>
  )
}
