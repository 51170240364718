'use client'
import { useRef, useMemo } from 'react'
import { RaButton } from '@components/core/index.core'
import clsx from 'clsx'
import { ICONS } from '@constants/icons.constants'
import { useViewport } from '@utilities/hooks/useViewport.hooks'
import styles from './ra-tabs.module.scss'

export const RaTabsSlider = ({ tabs, activeTabIndex }) => {
  const containerRef = useRef(null)
  const tabsRef = useRef(null)
  const { width } = useViewport()

  const goToStart = () => {
    if (tabsRef?.current) {
      tabsRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }
  }

  const goToEnd = () => {
    const posRight = tabsRef.current.scrollWidth
    tabsRef.current.scrollTo({ left: posRight, behavior: 'smooth' })
  }

  const renderControls = useMemo(() => {
    return width < tabsRef.current?.scrollWidth
  }, [width])

  return (
    <div
      ref={containerRef}
      className={styles['ra-tabs--mobile__button-wrapper']}>
      {renderControls && (
        <RaButton
          variant="icon"
          icon={ICONS.CHEVRON_LEFT}
          onClick={goToStart}
        />
      )}
      <div
        ref={tabsRef}
        className={styles['ra-tabs--mobile__tabs-wrapper']}>
        <div className={clsx(styles['ra-tabs--mobile__tab-list'])}>{tabs}</div>
      </div>
      {renderControls && (
        <RaButton
          variant="icon"
          icon={ICONS.CHEVRON_RIGHT}
          onClick={goToEnd}
        />
      )}
    </div>
  )
}
