'use client'
import { useMemo } from 'react'
import { RaButton } from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'
import dayjs from 'dayjs'

import { useSettings } from '@contexts/settings.context'

export const AcfBlogCtasButtons = ({ posts }) => {
  const { destination, themeSettings } = useSettings()
  const yearObject = useMemo(() => {
    if (!posts?.length) return null
    const homeRoute = !destination ? '' : destination
    const blogSlug = themeSettings.page_for_posts?.post_name

    return posts.reduce((cul, post) => {
      const { date } = post
      const year = dayjs(date).format('YYYY')
      /* eslint-disable */
      cul.hasOwnProperty(parseInt(year))
        ? cul[year].count++
        : (cul[year] = {
            url: `/${homeRoute}/${blogSlug}/archief/${year}/`,
            count: 1,
          })

      return cul
    }, {})
  }, [posts])

  const yearButtons = useMemo(() => {
    if (!yearObject) return null
    return Object.keys(yearObject).map(key => {
      return {
        label: key,
        count: yearObject[key].count,
        url: yearObject[key].url,
      }
    })
  }, [yearObject])

  return (
    <div style={{ display: 'flex', gap: 24 }}>
      {Loop(yearButtons, ({ label, count, url }, i) => (
        <RaButton
          key={i}
          label={`${label} (${count})`}
          link={{
            url,
            title: label,
          }}
        />
      ))}
    </div>
  )
}
