import {
  RaButton,
  RaHeading,
  RaIcon,
  RaImage,
  RaPaper,
  RaTypography,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './card-takeout.module.scss'

export const RaCardTakeout = ({ card }) => {
  return (
    <RaPaper
      padding={5}
      width="fit-content"
      hoverAnimations={['shadow', 'translateY']}
      link={{
        href: stripFrontendUrl(card.cta.url),
        title: card.cta.title,
      }}>
      <div className={styles['card']}>
        <div className={styles['card__content']}>
          <div className={styles['card__image']}>
            <RaImage
              image={card.icon}
              width={100}
              height={100}
            />
          </div>
          <RaHeading
            component="h3"
            noPadding>
            {card.title}
          </RaHeading>
          <RaTypography>{card.introduction}</RaTypography>
        </div>

        <RaButton
          label={card.cta.title}
          variant="text-primary"
          tabIndex={-1}
          endIcon={
            <RaIcon
              icon={ICONS.CHEVRON_RIGHT}
              color="primary"
            />
          }
        />
      </div>
    </RaPaper>
  )
}
