'use client'
import { useMemo } from 'react'
import {
  RaContainer,
  RaIcon,
  RaLink,
  RaPaper,
  RaTypography,
  RaButton,
} from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'

import { AcfLinkItemProps, RaFormSelectOption } from '@typings'

import styles from './ra-tabs.module.scss'
import { RaTabsSlider } from './RaTabsSlider'

export interface TabJSON extends RaFormSelectOption {
  target?: AcfLinkItemProps['target']
}

interface RaTabsProps {
  tabsData: TabJSON[]
  onTabSelect: (option: string | number) => void
  activeTabIndex: number
  isLink?: boolean
  mobileDirection?: 'vertical' | 'horizontal'
}

export const RaTabs = ({
  tabsData,
  onTabSelect,
  activeTabIndex,
  isLink = false,
  mobileDirection = 'vertical',
}: RaTabsProps) => {
  const renderDesktopTabs = useMemo(() => {
    if (!tabsData.length) return null
    return (
      <ul className={styles['ra-tabs__list']}>
        {Loop(tabsData, (tab: TabJSON, i: number) => {
          const isActive = activeTabIndex === i

          const TabElement = isLink ? (
            <RaLink
              key={tab.text}
              title={tab.text as string}
              url={tab.value as string}
              target={tab.target}
              textColor={isActive ? 'primary' : 'dark'}
              weight="semibold"
              removeUnderline
            />
          ) : (
            <button
              onClick={() => onTabSelect(i)}
              key={i}>
              <RaTypography
                component="span"
                color={isActive ? 'primary' : 'dark'}
                weight="semibold">
                {tab.text}
              </RaTypography>
            </button>
          )

          return (
            <li
              key={i}
              className={styles['ra-tabs__item']}>
              {TabElement}
              {activeTabIndex === i && (
                <RaIcon
                  minWidth={0}
                  minHeight={0}
                  icon={ICONS.NAV_UNDERLINE}
                />
              )}
            </li>
          )
        })}
      </ul>
    )
  }, [tabsData, activeTabIndex])

  const renderMobileTabs = useMemo(() => {
    return Loop(tabsData, (tab, i) => {
      const isActive = activeTabIndex === i

      let output = null
      if (isLink) {
        output = (
          <RaLink
            title={tab.text as string}
            url={tab.value as string}
            target={tab.target}
            textColor={isActive ? 'primary' : 'dark'}
            weight="semibold"
            removeUnderline
          />
        )
      } else {
        output = (
          <RaButton
            variant={isActive ? 'text-primary' : 'text-dark'}
            label={tab.text}
            onClick={() => onTabSelect(i)}
          />
        )
      }

      return (
        <li
          key={tab.text}
          className={styles['ra-tabs--mobile__item']}>
          {output}
          {isActive && (
            <RaIcon
              useRootEl
              minWidth={0}
              minHeight={0}
              icon={ICONS.NAV_UNDERLINE}
            />
          )}
        </li>
      )
    })
  }, [tabsData, activeTabIndex])

  return (
    <nav className={styles['ra-tabs']}>
      <RaContainer>
        <nav className={styles['ra-tabs--mobile']}>
          <RaPaper
            elevation="super-light"
            width="fit-content">
            {mobileDirection === 'horizontal' ? (
              <RaTabsSlider
                tabs={renderMobileTabs}
                activeTabIndex={activeTabIndex}
              />
            ) : (
              <div className={styles['ra-tabs--mobile__wrapper']}>
                {renderMobileTabs}
              </div>
            )}
          </RaPaper>
        </nav>
        <nav className={styles['ra-tabs--desktop']}>{renderDesktopTabs}</nav>
      </RaContainer>
    </nav>
  )
}
